<template>
  <div>
    <h5>Auto PT Head Reinitialization Config</h5>
    <table
      v-if="configs.length"
      :class="[
        'table kendo-table table-kendo mb-2',
        { 'is-invalid': this.$validator.errors.has('configs') },
      ]"
    >
      <colgroup>
        <col width="35" />
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t("active") }}</th>
          <th>{{ $t("options") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in configs"
          :key="`config-tableRow-${index}`"
        >
          <td>
            <toggle-button
              v-model="item.active"
              :width="50"
              :height="24"
              :font-size="12"
              class="m-0"
            />
          </td>
          <td>
            <div class="btn-group btn-group-sm flex-wrap">
              <button
                class="btn btn-sm btn-secondary"
                @click="removeItem(index)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t("remove") }}</span>
              </button>
              <button
                class="btn btn-sm btn-secondary"
                @click="moveUp(index)"
              >
                <font-awesome-icon icon="arrow-up" />
              </button>
              <button
                class="btn btn-sm btn-secondary"
                @click="moveDown(index)"
              >
                <font-awesome-icon icon="arrow-down" />
              </button>
              <input
                v-model="item.reinitTimeStart"
                placeholder="07:30"
                type="text"
                style="width: 45px"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <hr v-else />
    <button
      class="btn btn-sm btn-primary float-right"
      @click="addItem()"
    >
      <font-awesome-icon
        class="mr-2"
        icon="plus"
      />
      <span>{{ $t("add") }}</span>
    </button>
    <div class="clearfix" />
  </div>
</template>

<script>
export default {
  name: "PtHeadConfiguration",
  props: {
    configs: {
      type: Array,
      required: true,
    },
  },
  created() {
    if (!this.configs) {
      this.configs = [{active: true,reinitTimeStart: "07:30" }]
    }
  },
  methods: {
    moveUp(config) {
      if (config != 0) {
        var prevItem = this.configs[config - 1];
        var currentItem = this.configs[config];
        var currId = currentItem.id;
        var prevId = prevItem.id;
        currentItem.id = prevId;
        prevItem.id = currId;
        this.configs.splice(config - 1, 1, currentItem);
        this.configs.splice(config, 1, prevItem);
        this.update();
      }
    },
    moveDown(config) {
      var count = this.configs.length;
      if (config < count - 1) {
        var nextItem = this.configs[config + 1];
        var currentItem = this.configs[config];
        var currId = currentItem.id;
        var nextId = nextItem.id;
        currentItem.id = nextId;
        nextItem.id = currId;
        this.configs.splice(config + 1, 1, currentItem);
        this.configs.splice(config, 1, nextItem);
        this.update();
      }
    },
    addItem() {
      this.configs.push({ active: true, reinitTimeStart: "07:30" });
      this.update();
    },
    removeItem(removeIndex) {
      this.configs.splice(removeIndex, 1);
      this.update();
    },
    update() {
      this.$emit("update");
    },
  }
};
</script>
